import "./footer.css"

const Footer = () => {
    return(
        <div className="fContainer">
            <div className="fText">Copyright © 2023 Whitestone Funding. All rights reserved.</div>
        </div>
    )
}

export default Footer